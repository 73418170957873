import React, { useState, useEffect, useContext } from "react";
import { AuthCountext } from "./AuthCountext";
import Expire from "./subcomponent/Expire";
import Bar from "./subcomponent/Bar";
import DoughnutChart from "./subcomponent/Doughnut";
import Loader from "./Loader";
import InfoDiv from "./subcomponent/InfoDiv";
import { ChartsData } from "./ChartsData";
import { Totals } from "./Totals";
import Documents from "./Documents";
import {
  BranchesOutlined,
  ContactsOutlined,
  DollarOutlined,
  IdcardOutlined,
  TeamOutlined,
} from "@ant-design/icons";

const DashboardComp = () => {
  const { theme } = useContext(AuthCountext);
  const totals = Totals();
  const Data = ChartsData();
  const [isloading, setIsloading] = useState(true);
  const [isIdExpire, setIsIdExpire] = useState(true);
  const [isPassExpire, setIsPassExpire] = useState(true);

  const list = [
    {
      title: "عدد الموظفين",
      result: totals.totalEmployees,
      x: Data.branchName,
      y: Data.count,
      total: Data.employeesTotal,
      icon: <TeamOutlined />,
    },
    {
      title: "متوسط الرواتب",
      result: totals.avg,
      x: Data.branchNameAvg,
      y: Data.avgAmount,
      total: Data.avgsTotal,
      icon: <DollarOutlined />,
    },
    {
      title: "عدد الفروع",
      result: totals.totalBranches,
      icon: <BranchesOutlined />,
    },
    {
      title: "عدد الوظائف",
      result: totals.jobs,
      icon: <ContactsOutlined />,
    },
  ];

  const expireIdMsg = (msg) => {
    setIsIdExpire(msg);
  };
  const expirePassMsg = (msg) => {
    setIsPassExpire(msg);
  };

  useEffect(() => {
    expireIdMsg(isIdExpire);
    expirePassMsg(isPassExpire);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const titels = [
    {
      title: "هويات منتهية أو قاربت على الانتهاء",
      info: "idExpireDate",
      icon: <IdcardOutlined />,
    },
    {
      title: "جوازات منتهية أو قاربت على الانتهاء",
      info: "passExpireDate",
      icon: <IdcardOutlined />,
    },
  ];

  const thisYear = new Date().getFullYear()

  return (
    <div className="flex flex-column align-start justify-start gap-20 full-width">
      <div
        className={`flex flex-wrap background-${theme} color-${theme} gap-20 full-width`}
        style={{ flexGrow: 1 }}
      >
        <InfoDiv list={list} isloading={isloading} />
      </div>
      <div
        className={`flex flex-row background-${theme} color-${theme} gap-10 media-flex-column tablet-flex-culomn`}
        style={{ width: "100%", flexWrap: "nowrap", flexGrow: "1" }}
      >
        <div
          className="flex flex-row flex-wrap gap-20 full-width justify-space-between"
          style={{ flexGrow: "inherit" }}
        >
          <div
            className={`flex flex-column p-20 border-radius shadow-${theme} background-div-${theme} justify-center text-align-center media-flex-column media-full-width media-900-width`}
            style={{ flexGrow: "0", width: "30%", height: "310px" }}
          >
            <Documents />
          </div>
          <div
            className={`flex flex-column p-20 border-radius shadow-${theme} justify-center text-align-center media-flex-column background-gradiant-${theme} media-full-width media-900-width`}
            style={{ flexGrow: "1", width: "20%", height: "310px" }}
          >
            <div className="flex flex-row media-flex-column justify-center align-center">
              <div
                className={`flex justify-center align-center text-align-center color-${theme} `}
                style={{ width: "100%", height: "100%" }}
              >
                <div>
                  <DoughnutChart
                    x={Data.branchName}
                    y={Data.count}
                    title="عدد الموظفين"
                    titleDisplay={true}
                    legendOn={true}
                    theme={theme}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className={`flex p-20 border-radius shadow-${theme} justify-center text-align-center media-flex-column background-gradiant-${theme} media-full-width`}
            style={{ flexGrow: "2", height: "310px", width: "40%" }}
          >
            <div
              className={`flex flex-column border-bottom-${theme}-3 justify-center`}
            >
              {isloading ? (
                <div className="flex flex-row justify-center align-center">
                  <Loader size="large" />
                </div>
              ) : (
                <Bar
                  datas={Data.barData}
                  labels={Data.months}
                  title={`رسم بياني يوضح إجمالي رواتب جميع الفروع للعام ${thisYear}`}
                  titleDisplay={true}
                  theme={theme}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-row flex-wrap gap-20 full-width justify-space-between"
        style={{ flexGrow: "inherit" }}
      >
        {isIdExpire && (
          <div
            className={`flex flex-column background-${theme} color-${theme} gap-20 media-align-center max-full-media`}
            style={{ flexGrow: "1", maxWidth: "25%" }}
          >
            <div
              className={`flex flex-row background-${theme} color-${theme} gap-20 media-flex-column full-width `}
            >
              <Expire
                title={titels[0].title}
                info={titels[0].info}
                expireMsg={expireIdMsg}
                icon={titels[0].icon}
              />
            </div>
          </div>
        )}
        {isPassExpire && (
          <div
            className={`flex flex-column background-${theme} color-${theme} gap-20 media-align-center max-full-media`}
            style={{ flexGrow: "1", maxWidth: "25%" }}
          >
            <div
              className={`flex flex-row background-${theme} color-${theme} gap-20 media-flex-column full-width`}
            >
              <Expire
                title={titels[1].title}
                info={titels[1].info}
                expireMsg={expirePassMsg}
                icon={titels[1].icon}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardComp;
